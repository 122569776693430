import { useRouter } from 'next/router';
import { useModalActions } from 'components/ModalManager/Context';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { ModelSelectorModalContainer, IListOption, MODELS, MODEL_OPTIONS } from '../ModelSelectorModalContainer/ModelSelectorModalContainer';

export const ADD_MODEL_MODAL_ID = 'ADD_MODEL_MODAL_ID';

const ROUTES = {
  [MODELS.PRE_TRAINED]: () => '/explore/models',
  [MODELS.CUSTOM]: (userId: string, appId: string) => `/${userId}/${appId}/models/new`,
  [MODELS.IMPORT]: (userId: string, appId: string) => `/${userId}/${appId}/models/import`,
  [MODELS.UPLOAD]: (userId: string, appId: string) => `/${userId}/${appId}/models/upload`,
};

export const AddModelModal = (props: { userOrOrgId: string; appId: string }) => {
  const { userOrOrgId, appId } = props;
  const { closeModal } = useModalActions();
  const router = useRouter();
  const { 'model-creation-huggingface-import': modelCreationHuggingfaceImport, 'model-creation-triton-upload': modelCreationTritonUpload } =
    useFeatureFlagsLD();
  // Set default as true as flag will be undefined in test
  const isHuggingFaceImportEnabled = typeof modelCreationHuggingfaceImport === 'boolean' ? modelCreationHuggingfaceImport : true;
  const isTritonUploadEnabled = typeof modelCreationTritonUpload === 'boolean' ? modelCreationTritonUpload : true;
  const hfFilteredModelOptions = isHuggingFaceImportEnabled ? MODEL_OPTIONS : MODEL_OPTIONS.filter((option) => option.id !== MODELS.IMPORT);
  const modelOptions = isTritonUploadEnabled ? hfFilteredModelOptions : hfFilteredModelOptions.filter((option) => option.id !== MODELS.UPLOAD);

  const onCancelHandler = () => {
    closeModal({ id: ADD_MODEL_MODAL_ID });
  };

  const onContinueHandler = (option: IListOption) => {
    // timeout to allow modal to close before navigating
    setTimeout(() => {
      const path = ROUTES[option.id]?.(userOrOrgId, appId);
      router.push(path);
    }, 0);
    closeModal({ id: ADD_MODEL_MODAL_ID });
  };

  return (
    <ModelSelectorModalContainer title="Use a Model" options={modelOptions} onCancelClick={onCancelHandler} onContinueClick={onContinueHandler} />
  );
};
